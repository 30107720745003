const BROWSEWRAP = 'browsewrap'
const CLICKWRAP = 'clickwrap'
const TOOLTIP = 'tooltip'

/**
 * @name EventMap
 */
export default {
  stretch: BROWSEWRAP,
  banner: BROWSEWRAP,
  modal: CLICKWRAP,
  tooltip: TOOLTIP,
}

